<template>
  <div>
    <v-overlay :value="isLoadingSpinner" :opacity="`0.5`" zIndex="99999"
      ><v-progress-circular
        indeterminate
        size="70"
        width="7"
        color="primary"
      ></v-progress-circular
    ></v-overlay>
    <v-dialog
      v-if="selected"
      scrollable
      v-model="showMessageDialog"
      max-width="950px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ selected.subject }}</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12" md="12" lg="12">
              <span class="text-bold">{{ selected.from }}</span> {{ ` / ` }}
              <span class="text-bold">{{ selected.to }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="12" lg="12">
              <div
                v-html="selected.html"
                class="px-8"
                style="font-size: 18px; line-height: 1.6"
              ></div>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="12"
              ><v-btn color="blue darken-1" @click="downloadMessageExcel">
                EXPORT EXCEL
              </v-btn></v-col
            ></v-row
          >
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeMessageDialog">
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card elevation="2" outlined shaped tile>
      <v-card-title>Correo electrónico de ventas</v-card-title>
      <v-snackbar v-model="snackbar" :color="snackbar_class" elevation="24">
        {{ text_snackbar }}
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <ve-table
              id="sales_emails_table"
              fixed-header
              max-height="calc(100vh - 330px)"
              :columns="columns"
              :table-data="salesEmail"
              :border-around="true"
              :border-x="true"
              :border-y="true"
              :scroll-width="`1200px`"
              :sort-option="sortOption"
              :cell-style-option="cellStyleOption"
            />
            <div v-show="showEmpty" class="empty-data">Data Empty.</div>
            <div class="table-pagination mt-2 text-right">
              <ve-pagination
                :total="total"
                :page-index="pageIndex"
                :page-size="pageSize"
                :page-size-option="pageOptions"
                @on-page-number-change="pageNumberChange"
                @on-page-size-change="pageSizeChange"
              />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "@/services/apiService";
import { logInfo, logError } from "@/utils";
import _ from "lodash";
import download from "js-file-download";
import moment from "moment";

export default {
  name: "SalesEmailTable",
  components: {},
  data: function() {
    return {
      total: 0,
      salesEmail: [],

      /********** vue-easytable options **********/
      loadingInstance: null,
      sorting: null,
      sortOption: {
        sortChange: params => {
          this.sortChange(params);
        }
      },
      pageIndex: 1,
      pageSize: 100,
      pageOptions: [10, 50, 100],
      filterable_col_list: ["id", "date", "email", "status"],
      requiredRules: [v => !!v || "Required"],
      requiredRulesArray: [v => (!!v && v.length > 0) || "Required"],
      cellStyleOption: {
        headerCellClass: ({ column, rowIndex }) => {
          if (this.filterable_col_list.includes(column.field)) {
            return "filterable-column " + column.field;
          }
        },
        bodyCellClass: ({ row, column, rowIndex }) => {
          let className = "";
          if (rowIndex % 2 !== 0) {
            className = "table-body-cell-even";
          } else {
            className = "table-body-cell-odd";
          }
          return className;
        }
      },
      filterlist_fetch_url:
        "reporting/sales/salespedidoemail/salesemail/getfilterlist",
      filterCriteria: {
        id: {},
        date: {},
        email: {},
        status: {}
      },
      showEmpty: false,

      loading: true,
      options: {},
      selected: null,
      showMessageDialog: false,
      valid: true,
      isSaving: false,

      /********** snackbar **********/
      snackbar: false,
      text_snackbar: "The imported file is invalid.",
      vertical: true,
      snackbar_class: "deep-purple",

      /********************************** Loading Spinner Overlay **********************************/
      isLoadingSpinner: false
    };
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi();
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters("auth", ["isManager", "user"]),
    columns() {
      let common_cols = [
        {
          key: "id",
          title: "ID",
          field: "id",
          align: "left",
          width: 30,
          sortBy: "ASC",
          fixed: "left",
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <input
                    type="number"
                    class="filter_date_picker"
                    v-model={this.filterCriteria["id"]["from"]}
                    value={this.filterCriteria["id"]["from"]}
                    placeholder="From - "
                  />
                  <br />
                  <input
                    type="number"
                    class="filter_date_picker"
                    v-model={this.filterCriteria["id"]["to"]}
                    value={this.filterCriteria["id"]["to"]}
                    placeholder="To - "
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "id")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "date",
          title: "Fecha",
          field: "date",
          align: "left",
          width: 65,
          sortBy: "ASC",
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <input
                    type="date"
                    class="filter_date_picker"
                    v-model={this.filterCriteria["date"]["from"]}
                    value={this.filterCriteria["date"]["from"]}
                    placeholder="From - "
                  />
                  <br />
                  <input
                    type="date"
                    class="filter_date_picker"
                    v-model={this.filterCriteria["date"]["to"]}
                    value={this.filterCriteria["date"]["to"]}
                    placeholder="To - "
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "date")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "email",
          title: "Correo electrónico",
          field: "email",
          align: "left",
          width: 80,
          sortBy: "",
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <filterableAjaxCheckboxes
                    v-model={this.filterCriteria["email"]}
                    apiUrl={`${this.filterlist_fetch_url}`}
                    columnName={`email`}
                    isFullText={true}
                    filterCriteria={this.filterCriteria["email"]}
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "email")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "status",
          title: "Estado",
          field: "status",
          align: "left",
          width: 45,
          sortBy: "",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return this.getStatus(row.status);
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <v-checkbox
                    v-model={this.filterCriteria["status"]["pending"]}
                    label="Pendiente"
                    value="PENDING"
                  ></v-checkbox>
                  <v-checkbox
                    v-model={this.filterCriteria["status"]["completed"]}
                    label="Terminado"
                    value="COMPLETED"
                  ></v-checkbox>
                  <v-checkbox
                    v-model={this.filterCriteria["status"]["error"]}
                    label="Error"
                    value="ERROR"
                  ></v-checkbox>
                  <v-checkbox
                    v-model={this.filterCriteria["status"]["timeout"]}
                    label="Se acabó el tiempo"
                    value="TIMEOUT"
                  ></v-checkbox>
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "status")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "error",
          title: "Error",
          field: "error",
          align: "left",
          width: 80
        },
        {
          key: "q",
          title: "Ir",
          field: "actions",
          align: "center",
          width: 35,
          fixed: "right",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <div>
                <v-btn
                  color="blue darken-1"
                  text
                  on-click={() => this.onItemShow(row)}
                >
                  <b-icon icon="box-arrow-up-right" aria-hidden="true"></b-icon>
                </v-btn>
              </div>
            );
          }
        }
      ];
      return [...common_cols];
    },
    buttonText() {
      return this.isSelecting ? "Uploading..." : this.defaultButtonText;
    }
  },
  methods: {
    getStatus(status, flag) {
      if (status === "PENDING") {
        return (
          <b-badge variant="warning" class="text-h6">
            Pendiente
          </b-badge>
        );
      }
      if (status === "COMPLETED") {
        return (
          <b-badge variant="success" class="text-h6">
            Terminado
          </b-badge>
        );
      }
      if (status === "TIMEOUT") {
        return (
          <b-badge variant="dark" class="text-h6">
            Tiempo límite
          </b-badge>
        );
      }
      return (
        <b-badge variant="danger" class="text-h6">
          Error
        </b-badge>
      );
    },

    async downloadMessageExcel() {
      this.showLoadingInstance();
      let fetch_url =
        "reporting/sales/salespedidoemail/salesemail/downloadmessage";
      let body = {
        email: this.selected.email,
        worksessionSalesParentId: this.selected.worksessionSalesParentId
      };
      try {
        const response = await ApiService.post(fetch_url, body, {
          responseType: "blob"
        });
        download(response, "message_template.xlsx");
        this.closeLoadingInstance();
      } catch (error) {
        this.closeLoadingInstance();
      }
    },

    getQueryParams(options) {
      let query_params_string = "";
      let query_params = [];
      query_params.push("page=" + this.pageIndex);
      query_params.push("itemsPerPage=" + this.pageSize);
      if (this.sorting !== null) {
        for (const [key, value] of Object.entries(this.sorting)) {
          if (value !== null && value !== "") {
            query_params.push("sortby=" + key);
            query_params.push(
              "sortdesc=" + (value === "desc" ? "true" : "false")
            );
          }
        }
      }

      let filterModel = [];
      for (const [key, value] of Object.entries(this.filterCriteria)) {
        if (value !== "" && value !== null) {
          if (Object.keys(value).length > 0) {
            filterModel.push({
              columnField: key,
              filterValue: value
            });
          }
        }
      }
      let filter_columns = document.querySelectorAll(".filterable-column");
      for (let i = 0; i < filter_columns.length; i++) {
        filter_columns[i].classList.remove("filter_active");
      }
      if (filterModel.length > 0) {
        filterModel.map(filter_item => {
          for (let i = 0; i < filter_columns.length; i++) {
            if (filter_columns[i].classList.contains(filter_item.columnField)) {
              filter_columns[i].classList.add("filter_active");
            }
          }
          return filter_item;
        });
      }

      query_params_string += query_params.join("&");
      if (filterModel.length > 0) {
        query_params_string +=
          "&filterModel=" + encodeURIComponent(JSON.stringify(filterModel));
      }
      return query_params_string;
    },

    async getDataFromApi() {
      this.showLoadingInstance();
      this.loading = true;
      let query_params_string = this.getQueryParams(this.options);
      let fetch_url = "reporting/sales/salespedidoemail/salesEmail";
      if (query_params_string !== "") {
        fetch_url += "?" + query_params_string;
      }
      const resp = await ApiService.get(fetch_url);
      this.total = resp.total;
      this.salesEmail = resp.data;
      this.loading = false;
      this.closeLoadingInstance();
    },

    async onItemShow(item) {
      if (item.status === "ERROR") {
        logError("No hay correo electrónico cuando se produce un error.");
      } else {
        await this.getMessage(item);
        this.showMessageDialog = true;
      }
    },

    async getMessage(item) {
      const { email, worksessionSalesParentId } = item;
      let fetch_url = "reporting/sales/salespedidoemail/salesemail/getmessage";
      this.isLoadingSpinner = true;
      try {
        const resp = await ApiService.post(fetch_url, {
          email,
          worksessionSalesParentId
        });
        this.selected = resp.data;
        this.selected = {
          ...this.selected,
          email,
          worksessionSalesParentId
        };
      } catch (error) {
        console.log(error);
      }
      this.isLoadingSpinner = false;
    },

    searchConfirm(closeFn) {
      closeFn();
      this.getDataFromApi();
    },

    searchCancel(closeFn, cancelFilterKey) {
      closeFn();
      this.filterCriteria[cancelFilterKey] = {};
      this.getDataFromApi();
    },

    closeMessageDialog() {
      this.showMessageDialog = false;
      this.$nextTick(() => {
        this.selected = null;
      });
    },

    pageNumberChange(pageIndex) {
      this.pageIndex = pageIndex;
      this.getDataFromApi();
    },

    pageSizeChange(pageSize) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
      this.getDataFromApi();
    },

    sortChange(params) {
      this.sorting = params;
      this.getDataFromApi();
    },

    showLoadingInstance() {
      this.loadingInstance = this.$veLoading({
        target: document.querySelector("#sales_emails_table"),
        name: "flow" // gird, flow, wave, bounce,
      });
      this.loadingInstance.show();
    },

    closeLoadingInstance() {
      this.loadingInstance.close();
    },

    init() {
      this.getDataFromApi();
    }
  },

  async mounted() {
    this.init();
  }
};
</script>

<style>
.v-btn {
  text-transform: capitalize;
}
table tr td .v-btn {
  margin-bottom: 0 !important;
}
.filter_row td {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
td.ve-table-body-td.table-body-cell-even {
  background: #ddd !important;
}
td[col-key="logo"].ve-table-body-td {
  text-align: center !important;
}
.table-body-cell-yellow {
  background-color: #e3e308bf !important;
}
.table-body-cell-green {
  background-color: #12e112 !important;
}
.table-body-cell-red {
  background-color: #df2121 !important;
}
</style>
