<template>
  <div>
    <v-card elevation="2" outlined shaped tile>
      <v-card-title>Configuración de correo electrónico de ventas</v-card-title>
      <v-snackbar v-model="snackbar" :color="snackbar_class" elevation="24">
        {{ text_snackbar }}
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>

      <v-dialog
        v-if="selected"
        persistent
        scrollable
        v-model="showEdit"
        max-width="800px"
      >
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-autocomplete
                      v-model="selected.operatorId"
                      v-bind:label="`Operador`"
                      v-bind:placeholder="$t('start_typing_to_search')"
                      item-text="name"
                      item-value="id"
                      :items="filtered_operators"
                      hide-no-data
                      filled
                      required
                      :rules="requiredRules"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-autocomplete
                      v-model="selected.chainId"
                      v-bind:label="`Cadena`"
                      v-bind:placeholder="$t('start_typing_to_search')"
                      item-text="name"
                      item-value="id"
                      :items="filtered_chains"
                      hide-no-data
                      filled
                      required
                      :rules="requiredRules"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-combobox
                      v-bind:placeholder="$t('start_typing_to_search')"
                      v-model="selectedEmails"
                      label="Email"
                      :items="filtered_emails"
                      :search-input.sync="search_email"
                      multiple
                      small-chips
                      persistent-hint
                      required
                      :rules="requiredRulesArray"
                    ></v-combobox>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-combobox
                      v-bind:placeholder="$t('start_typing_to_search')"
                      v-model="selectedCCs"
                      :items="filtered_ccs"
                      label="CC"
                      multiple
                      small-chips
                      persistent-hint
                    ></v-combobox>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">
                {{ $t("cancel") }}
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                :loading="isSaving"
                @click="onSaveClick(selected)"
              >
                {{ $t("save") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>

      <v-dialog v-model="dialogDelete" max-width="550px">
        <v-card>
          <v-card-title class="text-h6 text-center">
            ¿Está seguro de que desea eliminar esta columna?
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDelete">{{
              $t("cancel")
            }}</v-btn>
            <v-btn color="blue darken-1" text @click="deleteItemConfirm"
              >OK</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-btn
              v-if="!isManager"
              color="primary"
              dark
              class="mb-2"
              @click="onItemEdit"
            >
              {{ $t("newitem") }}
            </v-btn>
            <v-btn
              color="warning"
              dark
              class="mb-2 ml-2"
              @click="downloadExcel"
            >
              <b-icon icon="file-excel-fill" aria-hidden="true"></b-icon>
              Export Excel
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <ve-table
              id="sales_emails_configs_table"
              fixed-header
              max-height="calc(100vh - 330px)"
              :columns="columns"
              :table-data="salesEmailConfig"
              :border-around="true"
              :border-x="true"
              :border-y="true"
              :scroll-width="`1200px`"
              :sort-option="sortOption"
              :cell-style-option="cellStyleOption"
            />
            <div v-show="showEmpty" class="empty-data">Data Empty.</div>
            <div class="table-pagination mt-2 text-right">
              <ve-pagination
                :total="total"
                :page-index="pageIndex"
                :page-size="pageSize"
                :page-size-option="pageOptions"
                @on-page-number-change="pageNumberChange"
                @on-page-size-change="pageSizeChange"
              />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "@/services/apiService";
import { logInfo, logError } from "@/utils";
import _ from "lodash";
import download from "js-file-download";
import moment from "moment";

export default {
  name: "SalesEmailConfigsTable",
  components: {},
  data: function() {
    return {
      total: 0,
      salesEmailConfig: [],
      /********** vue-easytable options **********/
      loadingInstance: null,
      sorting: null,
      sortOption: {
        sortChange: params => {
          this.sortChange(params);
        }
      },
      pageIndex: 1,
      pageSize: 100,
      pageOptions: [10, 50, 100],
      filterable_col_list: ["operatorId", "chainId", "email", "id", "CC"],
      cellStyleOption: {
        headerCellClass: ({ column, rowIndex }) => {
          if (this.filterable_col_list.includes(column.field)) {
            return "filterable-column " + column.field;
          }
        },
        bodyCellClass: ({ row, column, rowIndex }) => {
          if (rowIndex % 2 !== 0) {
            return "table-body-cell-even";
          } else {
            return "table-body-cell-odd";
          }
        }
      },
      filterlist_fetch_url: "reporting/sales/salespedidoemail/getfilterlist",
      filterCriteria: {
        operatorId: {},
        chainId: {},
        email: {},
        CC: {},
        id: {}
      },
      showEmpty: false,

      loading: true,
      options: {},
      editedIndex: -1,
      selected: {},
      valid: true,
      requiredRules: [v => !!v || "Required"],
      requiredRulesArray: [v => (!!v && v.length > 0) || "Required"],
      showEdit: false,
      dialogDelete: false,
      deleteItemId: -1,

      filtered_operators: [],
      filtered_chains: [],
      filtered_emails: [],
      filtered_ccs: [],
      search_email: null,
      isSaving: false,

      selectedEmails: [],
      selectedCCs: [],

      /********** snackbar **********/
      snackbar: false,
      text_snackbar: "The imported file is invalid.",
      vertical: true,
      snackbar_class: "deep-purple"
    };
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi();
      },
      deep: true
    },
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    search_email(newVal, oldVal) {},
    selectedEmails(newVal, oldVal) {
      if (newVal.length) {
        let lastEl = this.selectedEmails[this.selectedEmails.length - 1];
        if (!this.isValidEmail(lastEl)) {
          logError("Email is Invalid");
          this.$nextTick(() => this.selectedEmails.pop());
        } else {
          this.filtered_emails = newVal;
        }
      }
    },
    selectedCCs(newVal, oldVal) {
      if (newVal.length) {
        let lastEl = this.selectedCCs[this.selectedCCs.length - 1];
        if (!this.isValidEmail(lastEl)) {
          logError("Email is Invalid");
          this.$nextTick(() => this.selectedCCs.pop());
        } else {
          this.filtered_ccs = newVal;
        }
      }
    }
  },
  computed: {
    ...mapGetters("auth", ["isManager", "user"]),
    formTitle() {
      return this.editedIndex === -1 || this.editedIndex === undefined
        ? this.$t("newitem")
        : this.$t("edititem");
    },
    columns() {
      let common_cols = [
        {
          key: "id",
          title: "ID",
          field: "id",
          align: "left",
          width: 35,
          sortBy: "ASC",
          fixed: "left",
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <input
                    type="number"
                    class="filter_date_picker"
                    v-model={this.filterCriteria["id"]["from"]}
                    value={this.filterCriteria["id"]["from"]}
                    placeholder="From - "
                  />
                  <br />
                  <input
                    type="number"
                    class="filter_date_picker"
                    v-model={this.filterCriteria["id"]["to"]}
                    value={this.filterCriteria["id"]["to"]}
                    placeholder="To - "
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "id")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "operatorId",
          title: "Operador",
          field: "operatorId",
          align: "left",
          width: 80,
          sortBy: "",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return <span>{row?.operator && row.operator.name}</span>;
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <filterableAjaxCheckboxes
                    v-model={this.filterCriteria["operatorId"]}
                    apiUrl={`${this.filterlist_fetch_url}`}
                    columnName={`operatorId`}
                    isFullText={true}
                    filterCriteria={this.filterCriteria["operatorId"]}
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "operatorId")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "chainId",
          title: "Cadena",
          field: "chainId",
          align: "left",
          width: 80,
          sortBy: "",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return <span>{row?.chain && row.chain.name}</span>;
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <filterableAjaxCheckboxes
                    v-model={this.filterCriteria["chainId"]}
                    apiUrl={`${this.filterlist_fetch_url}`}
                    columnName={`chainId`}
                    isFullText={true}
                    filterCriteria={this.filterCriteria["chainId"]}
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "chainId")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "email",
          title: "Correo electrónico",
          field: "email",
          align: "left",
          width: 90,
          sortBy: "",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return <span>{row?.email && row.email.split(",").join("\n")}</span>;
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <filterableAjaxCheckboxes
                    v-model={this.filterCriteria["email"]}
                    apiUrl={`${this.filterlist_fetch_url}`}
                    columnName={`email`}
                    isFullText={true}
                    filterCriteria={this.filterCriteria["email"]}
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "email")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "CC",
          title: "CC",
          field: "CC",
          align: "left",
          width: 90,
          sortBy: "",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return <span>{row?.CC && row.CC.split(",").join("\n")}</span>;
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <filterableAjaxCheckboxes
                    v-model={this.filterCriteria["CC"]}
                    apiUrl={`${this.filterlist_fetch_url}`}
                    columnName={`CC`}
                    isFullText={true}
                    filterCriteria={this.filterCriteria["CC"]}
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "CC")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "q",
          title: "Editar",
          field: "actions",
          align: "center",
          width: 45,
          fixed: "right",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <div>
                <v-icon
                  medium
                  color="primary"
                  class="mr-2"
                  on-click={() => this.onItemEdit(row)}
                >
                  mdi-pencil
                </v-icon>
                <v-icon
                  color="red"
                  class="mr-3"
                  on-click={() => this.onItemRemove(row)}
                >
                  mdi-delete
                </v-icon>
              </div>
            );
          }
        }
      ];
      return [...common_cols];
    },
    buttonText() {
      return this.isSelecting ? "Uploading..." : this.defaultButtonText;
    }
  },
  methods: {
    isValidEmail(email) {
      const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      return regex.test(email);
    },
    getQueryParams(options) {
      let query_params_string = "";
      let query_params = [];
      query_params.push("page=" + this.pageIndex);
      query_params.push("itemsPerPage=" + this.pageSize);
      if (this.sorting !== null) {
        for (const [key, value] of Object.entries(this.sorting)) {
          if (value !== null && value !== "") {
            query_params.push("sortby=" + key);
            query_params.push(
              "sortdesc=" + (value === "desc" ? "true" : "false")
            );
          }
        }
      }

      let filterModel = [];
      for (const [key, value] of Object.entries(this.filterCriteria)) {
        if (value !== "" && value !== null) {
          if (Object.keys(value).length > 0) {
            filterModel.push({
              columnField: key,
              filterValue: value
            });
          }
        }
      }
      let filter_columns = document.querySelectorAll(".filterable-column");
      for (let i = 0; i < filter_columns.length; i++) {
        filter_columns[i].classList.remove("filter_active");
      }
      if (filterModel.length > 0) {
        filterModel.map(filter_item => {
          for (let i = 0; i < filter_columns.length; i++) {
            if (filter_columns[i].classList.contains(filter_item.columnField)) {
              filter_columns[i].classList.add("filter_active");
            }
          }
          return filter_item;
        });
      }

      query_params_string += query_params.join("&");
      if (filterModel.length > 0) {
        query_params_string +=
          "&filterModel=" + encodeURIComponent(JSON.stringify(filterModel));
      }
      return query_params_string;
    },

    async downloadExcel() {
      this.showLoadingInstance();
      const { pageIndex, pageSize, sorting, filterCriteria } = this;
      let query_params_string = this.getQueryParamsGlobal({
        pageIndex,
        pageSize,
        sorting,
        filterCriteria,
        filterableColumnsSelector:
          "#sales_emails_configs_table .filterable-column"
      });
      console.log(query_params_string);
      let fetch_url = "reporting/sales/salespedidoemail/downloadexcel";
      if (query_params_string !== "") {
        fetch_url += "?" + query_params_string;
      }
      try {
        const response = await ApiService.get(fetch_url, {
          responseType: "blob"
        });
        download(response, "sales_emails_configs_table.xlsx");
        this.closeLoadingInstance();
      } catch (error) {
        this.closeLoadingInstance();
      }
    },

    async getDataFromApi() {
      this.showLoadingInstance();
      this.loading = true;
      let query_params_string = this.getQueryParams(this.options);
      let fetch_url = "reporting/sales/salespedidoemail";
      if (query_params_string !== "") {
        fetch_url += "?" + query_params_string;
      }
      const resp = await ApiService.get(fetch_url);
      this.salesEmailConfig = resp.data;
      this.total = resp.total;
      this.filtered_chains = resp.chain_list;
      this.filtered_operators = resp.operator_list;
      this.loading = false;
      this.closeLoadingInstance();
    },

    onItemEdit(item) {
      this.selected = this.createSelectedModel(item);
      this.editedIndex = this.selected.id;
      this.showEdit = true;
    },

    createSelectedModel(item) {
      let emails = _.get(item, "email");
      let ccs = _.get(item, "CC");
      let model = {
        id: _.get(item, "id"),
        operatorId: _.get(item, "operatorId"),
        chainId: _.get(item, "chainId")
      };
      if (emails) {
        this.selectedEmails = emails.split(",");
        this.filtered_emails = this.selectedEmails;
      }
      if (ccs) {
        this.selectedCCs = ccs.split(",");
        this.filtered_ccs = this.selectedCCs;
      }
      return { ...model };
    },

    async onSaveClick(item) {
      if (this.$refs.form.validate()) {
        this.isSaving = true;
        let body = _.omit(item);
        body = {
          ...body,
          email: this.selectedEmails.join(","),
          CC: this.selectedCCs.join(",")
        };
        let save_res = null;
        try {
          if (body.id) {
            save_res = await ApiService.put(
              `reporting/sales/salespedidoemail/update/${body.id}`,
              body
            );
          } else {
            save_res = await ApiService.post(
              `reporting/sales/salespedidoemail/create`,
              body
            );
          }
          if (save_res.success) {
            logInfo("actualizada");
            this.showEdit = false;
            this.getDataFromApi();
          } else {
            logError(save_res.error);
          }
        } catch (error) {}
        this.selectedEmails = [];
        this.selectedCCs = [];
        this.isSaving = false;
      }
    },

    searchConfirm(closeFn) {
      closeFn();
      this.getDataFromApi();
    },

    searchCancel(closeFn, cancelFilterKey) {
      closeFn();
      this.filterCriteria[cancelFilterKey] = {};
      this.getDataFromApi();
    },

    close() {
      this.showEdit = false;
      this.$nextTick(() => {
        this.selected = null;
        this.editedIndex = -1;
        this.filtered_emails = [];
        this.filtered_ccs = [];
        this.selectedEmails = [];
        this.selectedCCs = [];
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.deleteItemId = -1;
      });
    },

    onItemRemove(item_id) {
      this.deleteItemId = item_id;
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      if (this.deleteItemId && this.deleteItemId.id > 0) {
        this.closeDelete();
        ApiService.delete(
          `reporting/sales/salespedidoemail/${this.deleteItemId.id}`
        ).then(() => {
          logInfo("eliminada");
          this.getDataFromApi();
        });
      }
    },

    pageNumberChange(pageIndex) {
      this.pageIndex = pageIndex;
      this.getDataFromApi();
    },

    pageSizeChange(pageSize) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
      this.getDataFromApi();
    },

    sortChange(params) {
      this.sorting = params;
      this.getDataFromApi();
    },

    showLoadingInstance() {
      this.loadingInstance = this.$veLoading({
        target: document.querySelector("#sales_emails_configs_table"),
        name: "flow" // gird, flow, wave, bounce,
      });
      this.loadingInstance.show();
    },

    closeLoadingInstance() {
      this.loadingInstance.close();
    },

    init() {
      this.getDataFromApi();
    }
  },

  async mounted() {
    this.init();
  }
};
</script>

<style>
.v-btn {
  text-transform: capitalize;
}
table tr td .v-btn {
  margin-bottom: 0 !important;
}
.filter_row td {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
td.ve-table-body-td.table-body-cell-even {
  background: #ddd !important;
}
td[col-key="logo"].ve-table-body-td {
  text-align: center !important;
}
</style>
