<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12" md="12">
        <SalesEmailConfigsTable></SalesEmailConfigsTable>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="12">
        <SalesEmailTable></SalesEmailTable
      ></v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "@/services/apiService";
import { logInfo, logError } from "@/utils";
import _ from "lodash";
import download from "js-file-download";
import moment from "moment";
import SalesEmailConfigsTable from "./SalesEmailConfigsTable.vue";
import SalesEmailTable from "./SalesEmailTable.vue";

export default {
  name: "SalesPedidoEmail",
  components: {
    SalesEmailConfigsTable,
    SalesEmailTable
  }
};
</script>
<style></style>
